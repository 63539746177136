import React, { useState, useEffect } from "react";
import { API } from "../../Redux/API";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import gif from "../Spinner/loader.gif";
function AdminautoLogin() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user_id, setuser_id] = useState(searchParams.get("user_id"));
  const [enpass, setenpass] = useState(searchParams.get("enpass"));
  const Navigate = useNavigate();
  const [loader, setloader] = useState(false);

  const SessionClear = async () => {
    sessionStorage.clear();
  };

  const AutoLogin = async () => {
    setloader(true);
    if ((user_id != undefined) && (user_id != null) && (enpass != undefined) && (enpass != null)) {
      let responce = await API.post("/Admin_login_Checknow", {
        uid: user_id,
        password: enpass,
      });
      
      console.log("Login res ", responce);
      if (responce.data.data.result == "Successfull") {
        // sessionStorage.setItem('isAuthenticated', true)
        sessionStorage.setItem("user", JSON.stringify(responce.data.data));
        
        toast.success("Login Successfully", {
          className: "copied-message",
        });
        Navigate("/dashboard");
        return true;
      } else {
        toast.error("User Id or Password Invalid ", {
          className: "copied-message",
        });
      }
    }
    
    else {     
      return;
    }
    setloader(false);

  };

  



  useEffect(() => {
    setloader(true);
    SessionClear();
    AutoLogin();
  }, []);

  return (
    <div>
     {loader && <img src={gif} className="LoaderSpinner" />}
    </div>
  );
}

export default AdminautoLogin;
