import React, { useState } from "react";
import Sidebar from "../Navbar/Sidebar";
import { useEffect } from "react";
import { API } from "../../Redux/API";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Select } from "antd";
import DOMPurify from "dompurify";

function Buyactivate() {
  const user = sessionStorage.getItem("user");
  let ress = JSON.parse(user);
  if (ress == undefined || ress == "" || ress == null) {
    window.location.href = "/login";
  }
  let uId = ress.uid_output;
  const [loader, setloader] = useState(false);

  const [AdminBalance, setAdminBalance] = useState(0);
  const [top_up, settop_up] = useState(0);
  const [isemailValid, setIsemailValid] = useState(true);
  const [PColor, setPColor] = useState("");
  const [PName, setPName] = useState("");
  const [PMobile, setPMobile] = useState("");
  const [PEMail, setPEMail] = useState("");
  const [PAddress, setPAddress] = useState("");
  const [PAmount, setPAmount] = useState("400");

  const getBetaWallet = async () => {
    try {
      const res = await API.get(`/DashboardDetails?uid=${uId}`);

      settop_up(res.data.data[0].top_up);
      setAdminBalance(res.data.data[0].Fund_Balance);
    } catch (e) {
      console.log("error", e);
    }
  };
  //handleActivationToken
  const ProductBuyNow = async () => {
    try {
      setloader(true);
      let uid = uId;
      
      if (parseFloat(PAmount) > parseFloat(AdminBalance)) {
        toast.error("Insufficient Activation Wallet Balance !!!", {
          className: "copied-message",
        });
        setloader(false);
        return;
      }

      let res1 = await API.post(`/SaveOrderDetail`, {
        userid: uid,
        Name: PName,
        ProductColor: PColor,
        Amount: PAmount,
        Phone: PMobile,
        Email: PEMail,
        FullAddress: PAddress,
      });

      res1 = res1.data.data.Result;
      if (res1 !== "Successful") {       
        toast.error(res1, {
          className: "copied-message",
        });
        setloader(false);
        return;
      }
      if (res1 == "Successful") {
        if (top_up === 0) {
          let responce = await API.post(`/ActivateUserbyFundnew`, {
            user_id: uid,
            referby: uid,
            amount: PAmount,
            fundtype: "FUNDS",
            txn: "",
            tokenamount: 0,
            BUSDAmount: PAmount,
            ActivationType: "100",
            RapidFundUser: 0,
            otp: "123",
          });
          console.log("responce", responce);
          responce = responce.data.data;
          if (responce == "successfully activate !!") {
            toast.success("Successfuly Activated!!!", {
              className: "copied-message",
            });
            setloader(false);
            window.location.reload();
            return;
          } else {
            toast.error(responce, {
              className: "copied-message",
            });
            setloader(false);
            return;
          }
        } else {
          let responce1 = await API.post(`/UpgrdeUserbyFundnew`, {
            user_id: uid,
            referby: uid,
            amount: PAmount,
            fundtype: "FUNDS",
            txn: "",
            tokenamount: 0,
            BUSDAmount: PAmount,
            ActivationType: "100",
            RapidFundUser: 0,
            otp: "123",
          });
          console.log("responce1", responce1);
          responce1 = responce1.data.data;
          if (responce1 == "successfully Upgrade !!") {
            toast.success("Successfuly Upgrade!!!", {
              className: "copied-message",
            });
            setloader(false);
            window.location.reload();
            return;
          } else {
            toast.error(responce1, {
              className: "copied-message",
            });
            setloader(false);
            return;
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      setloader(false);
    }
  };

  useEffect(() => {
    getBetaWallet();
  }, []);

  const handleChange_Color = (value) => {
    // console.log(`handleChange_status ${value}`);
    setPColor(value);
  };

  const handleEmailChange = (event) => {
    const emailValue = DOMPurify.sanitize(event.target.value).substring(0, 50);
    setPEMail(emailValue);

    // Use a regular expression to validate the email format
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsemailValid(emailPattern.test(emailValue));
  };

  return (
    <div className="nft_theme">
      <Sidebar />
      <div class="horizontalMenucontainer">
        <a
          href="#top"
          id="back-to-top"
          class="back-to-top rounded-circle shadow"
        >
          <i class="angle fe fe-chevron-up"></i>
        </a>
       
        <div class="page">
          
          <div class="main-content app-content">
            {/* <!-- container --> */}
            <div class="main-container">
              {/* <!-- breadcrumb --> */}
              <div class="breadcrumb-header justify-content-between">
                <div class="left-content">
                  <span class="main-content-title mg-b-0 mg-b-lg-1">
                  Activation
                  </span>
                </div>
                <div class="justify-content-center mt-2">
                  <ol class="breadcrumb breadcrumb-style3">
                    <li class="breadcrumb-item tx-15">
                      <a href="#">  Buy Activate</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                    Buy Activate
                    </li>
                  </ol>
                </div>
              </div>
          <div className="col-12 d-flex justify-content-center py-5 ">
            <div className="col-md-6 col-lg-5 col-xxl-4 col-10 width-mg card1">
              <div
                className="d-flex flex-column align-items-center profile-border profile-login  py-4 shadow-withdraw"
                id="withdraw-inp"
              >
                <h2 className="h-color col-12 py-2 px-4 border_bottom">
                  {" "}
                  Order Details
                </h2>
                <div className="col-11 mt-3 row align-items-center">
                  <p className="col-5 m-0 p-0">Wallet Balance</p>
                  <input
                    type="text"
                    className="p-2 profile-border col-7"
                    value={AdminBalance}
                  />
                </div>
                <div className="col-11 mt-3 row align-items-center">
                  <p className="col-5 m-0 p-0">Product Colour</p>
                  <Select
                    id="PColor"
                    className="col-7  m-0 p-0"
                    data-val="true"
                    data-val-required="Color is required"
                    value={PColor}
                    onChange={handleChange_Color}
                  >
                    <option value="">Select Color</option>
                    <option value="Black">Black</option>
                    <option value="Gray">Gray</option>
                    <option value="Blue">Blue</option>
                    <option value="Pink">Pink</option>
                    <option value="Green">Green</option>
                  </Select>
                </div>
                <div className="col-11 mt-3 row align-items-center">
                  <p className="col-5 m-0 p-0">Product Amount</p>
                  <input
                    type="text"
                    className="p-2 profile-border col-7"
                    value={PAmount}
                  />
                </div>
                <div className="col-11 mt-3 row align-items-center">
                  <p className="col-5 m-0 p-0">Name</p>
                  <input
                    type="text"
                    className="p-2 profile-border col-7"
                    value={PName}
                    onChange={(e) => {
                      setPName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-11 mt-3 row align-items-center">
                  <p className="col-5 m-0 p-0">Phone</p>
                  <input
                    type="text"
                    className="p-2 profile-border col-7"
                    value={PMobile}
                    onChange={(e) => {
                      setPMobile(e.target.value);
                    }}
                  />
                </div>
                <div className="col-11 mt-3 row align-items-center">
                  <p className="col-5 m-0 p-0">Email</p>
                  <input
                    type="email"
                    className="p-2 profile-border col-7"
                    value={PEMail}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="col-11 mt-3 row align-items-center">
                  <p className="col-5 m-0 p-0">Full Address With Pin Code</p>
                  <textarea
                    type="text"
                    className="p-2 profile-border col-7"
                    value={PAddress}
                    onChange={(e) => {
                      setPAddress(e.target.value);
                    }}
                  />
                </div>

                <a
                  href="#"
                  onClick={() => {
                    ProductBuyNow();
                  }}
                >
                  <div class="activate_btn col-12 mt-3 py-3 wdth_btn btn mb-3">
                    <h4>Buy Now</h4>
                  </div>
                </a>

                {/* <button
              className="col-5 mt-3 py-3 wdth_btn btn mb-3"
              style={{ color: "rgb(0, 0, 0)" }}
            >
              Buy Now
            </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}

export default Buyactivate;
