import React, { useEffect, useState } from "react";
import userIcon from "./user_icon.png";
import { useNavigate } from "react-router-dom";
import { API } from "../../Redux/API";
import Sidebar from "../Navbar/Sidebar";
import "../LevelTree/Tree.css";
import { Tooltip } from "antd";
import user3red from "../Team Details/tree/default.png";
import Active from "../Team Details/tree/green.png";
function LevelTree() {
  const user = sessionStorage.getItem("user");
  let ress = JSON.parse(user);
  if (ress == undefined || ress == "" || ress == null) {
    window.location.href = "/login";
  }
  let uId = ress.uid_output;
  const [firstTree, setfirstTree] = useState("");
  const [secondtree, setsecondtree] = useState([]);
  const [thirdTreeId, setthirdTreeId] = useState([]);
  const [thirdtree, setthirdtree] = useState([]);

  const handleSecondTreeClick = (id) => {
    setthirdTreeId([...thirdTreeId, id]);
    getTreeData3(id);
  };
  const getTreeData1 = async (itemuid) => {
    try {
      let res = await API.post("/LevelTree", {
        uid: itemuid,
        lvl: 2,
      });
      console.log("ResTreee1--->", res.data.data[0]);
      setfirstTree(res.data.data[0][0]);
    } catch (error) {
      console.log(error);
    }
  };
  const getTreeData2 = async (itemuid) => {
    try {
      let res = await API.post("/LevelTree", {
        uid: itemuid,
        lvl: 1,
      });
      // console.log("ResTreee2", res);
      setsecondtree(res.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getTreeData3 = async (item) => {
    try {
      if (item.downlinecount <= 0) {
        return;
      }
      getTreeData1(item.lvluid);
      getTreeData2(item.lvluid);
      // alert(item.lvluid)
      //       let res = await API.post("/LevelTree", {
      //         uid: item.lvluid,
      //         lvl: 1,
      //       });
      // console.log("getTreeData3--->", res);
      //       const updatedData = {
      //         lvluid: item.lvluid,
      //         data: res.data.data[0],
      //       };

      //       setthirdtree((prevThirdTrees) => [...prevThirdTrees, updatedData]);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("123",firstTree)
  useEffect(() => {
    getTreeData1(uId);
    getTreeData2(uId);
  }, []);
  return (
    <>
      <div className="nft_theme">
        <Sidebar />
        <div className="container">
          <div className="breadcrumb-header justify-content-between">
            <div className="left-content">
              <span className="main-content-title mg-b-0 mg-b-lg-1">
                Level Tree
              </span>
            </div>
            <div className="justify-content-center mt-2">
              <ol className="breadcrumb breadcrumb-style3">
                <li className="breadcrumb-item tx-15">
                  <a href="#">Team Details </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Level Tree
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
              <div className="card shadow">
                <div className="card-body p-0">
                  <div className="grid-margin">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="tree_view">
                          <ul>
                            <li>
                              <div
                                className="tree_view_parent"
                                onmouseover="bigImg(this)"
                                onmouseout="normalImg(this)"
                              >
                                <img
                                  src={Active}
                                  style={{ height: 70 }}
                                  alt=""
                                />{" "}
                                <br />
                                <Tooltip
                                  title={
                                    <>
                                      <div>
                                        <p className="text-white">
                                          Name : {firstTree.name}
                                        </p>
                                        <p className="text-white">
                                          Rank : {firstTree.rankname}
                                        </p>
                                        <p className="text-white">
                                          Team : {firstTree.downlinecount}
                                        </p>
                                        <p className="text-white">
                                          Team Business :{" "}
                                          {firstTree.totalbussiness}
                                        </p>
                                        <p className="text-white">
                                          Today Business :{" "}
                                          {firstTree.todaybussiness}
                                        </p>
                                      </div>
                                    </>
                                  }
                                >
                                  <div className=" copy_btn_set2">
                                    <button
                                      type="button"
                                      className={`btn ${firstTree.status} btn-sm`}
                                      data-html="true"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      style={{
                                        fontSize: "0.9em",
                                        padding: "3px",
                                        width: "65px",
                                        textAlign: "center",
                                      }}
                                      data-original-title=""
                                    >
                                      {firstTree.lvluid === uId ? (
                                        <>You</>
                                      ) : (
                                        <>{firstTree.lvluid}</>
                                      )}
                                    </button>
                                  </div>
                                </Tooltip>
                              </div>
                              <ul>
                                {secondtree.map((items, index) => {
                                  return (
                                    <>
                                      <li
                                        key={index}
                                        className="tree_element"
                                        data-id={637975}
                                        data-show={1}
                                      >
                                        <div
                                          className="tree_view_parent"
                                          data-id={637975}
                                        >
                                          <img
                                            // src={userIcon}
                                            src={
                                              items.status === "btn-success"
                                                ? Active
                                                : items.status === "btn-danger"
                                                ? user3red
                                                : userIcon
                                            }
                                            alt=""
                                            style={{
                                              height: 50,
                                              width: "auto",
                                            }}
                                            onClick={() =>
                                              handleSecondTreeClick(items)
                                            }
                                          />
                                          <br />
                                          <Tooltip
                                            title={
                                              <>
                                                <div>
                                                  <p className="text-white">
                                                    Name : {items.name}
                                                  </p>
                                                  <p className="text-white">
                                                    Rank : {items.rankname}
                                                  </p>
                                                  <p className="text-white">
                                                    Team : {items.downlinecount}
                                                  </p>
                                                  <p className="text-white">
                                                    Team Business :{" "}
                                                    {items.totalbussiness}
                                                  </p>
                                                  <p className="text-white">
                                                    Today Business :{" "}
                                                    {items.todaybussiness}
                                                  </p>
                                                </div>
                                              </>
                                            }
                                          >
                                            <button
                                              type="button"
                                              className={`btn ${items.status} btn-sm`}
                                              data-html="true"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title=""
                                              data-original-title=""
                                              style={{
                                                fontSize: "0.9em",
                                                padding: "3px",
                                                width: "65px",
                                                textAlign: "center",
                                              }}
                                              onClick={() =>
                                                handleSecondTreeClick(items)
                                              }
                                            >
                                              {items.lvluid}
                                            </button>
                                          </Tooltip>
                                        </div>

                                        {/* <ul>
                                          {
                                          Array.isArray(thirdtree[items?.lvluid]) &&
                                          thirdtree[items?.lvluid]?.map(
                                            (item, index) => {
                                              return (
                                                <>
                                                  <li
                                                    key={items.lvluid + index}
                                                    className="tree_element"
                                                    data-id={637975}
                                                    data-show={1}
                                                  >
                                                    <div
                                                      className="tree_view_parent"
                                                      data-id={637975}
                                                    >
                                                      <img
                                                        src={userIcon}
                                                        alt=""
                                                        style={{
                                                          height: 50,
                                                          width: "auto",
                                                        }}
                                                        onClick={() =>
                                                          handleSecondTreeClick(
                                                            item
                                                          )
                                                        }
                                                      />
                                                      <br />
                                                      <Tooltip
                                                        title={
                                                          <>
                                                            <div>
                                                              <p className="text-white">
                                                                Team :{" "}
                                                                {
                                                                  item.downlinecount
                                                                }
                                                              </p>
                                                            </div>
                                                          </>
                                                        }
                                                      >
                                                        <button
                                                          type="button"
                                                          className={`btn ${item.status} btn-sm`}
                                                          data-html="true"
                                                          data-toggle="tooltip"
                                                          data-placement="top"
                                                          title=""
                                                          data-original-title=""
                                                          style={{
                                                            fontSize: "0.9em",
                                                            padding: "3px",
                                                            width: "65px",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {item.lvluid}
                                                        </button>
                                                      </Tooltip>
                                                    </div>
                                                    <ul>
                                                      {thirdtree[
                                                        item?.lvluid
                                                      ]?.map((item1, index) => {
                                                        return (
                                                          <>
                                                            <li
                                                              key={
                                                                item1.lvluid +
                                                                index
                                                              }
                                                              className="tree_element"
                                                              data-id={637975}
                                                              data-show={1}
                                                            >
                                                              <div
                                                                className="tree_view_parent"
                                                                data-id={637975}
                                                              >
                                                                <img
                                                                  src={userIcon}
                                                                  alt=""
                                                                  style={{
                                                                    height: 50,
                                                                    width:
                                                                      "auto",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleSecondTreeClick(
                                                                      item
                                                                    )
                                                                  }
                                                                />
                                                                <br />
                                                                <Tooltip
                                                                  title={
                                                                    <>
                                                                      <div>
                                                                        <p className="text-white">
                                                                          Team :{" "}
                                                                          {
                                                                            item1.downlinecount
                                                                          }
                                                                        </p>
                                                                      </div>
                                                                    </>
                                                                  }
                                                                >
                                                                  <button
                                                                    type="button"
                                                                    className={`btn ${item1.status} btn-sm`}
                                                                    data-html="true"
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title=""
                                                                    data-original-title=""
                                                                    style={{
                                                                      fontSize:
                                                                        "0.9em",
                                                                      padding:
                                                                        "3px",
                                                                      width:
                                                                        "65px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    {
                                                                      item1.lvluid
                                                                    }
                                                                  </button>
                                                                </Tooltip>
                                                              </div>

                                                              <ul>
                                                                {thirdtree[
                                                                  item1?.lvluid
                                                                ]?.map(
                                                                  (
                                                                    item2,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <>
                                                                        <li
                                                                          key={
                                                                            item2.lvluid +
                                                                            index
                                                                          }
                                                                          className="tree_element"
                                                                          data-id={
                                                                            637975
                                                                          }
                                                                          data-show={
                                                                            1
                                                                          }
                                                                        >
                                                                          <div
                                                                            className="tree_view_parent"
                                                                            data-id={
                                                                              637975
                                                                            }
                                                                          >
                                                                            <img
                                                                              src={
                                                                                userIcon
                                                                              }
                                                                              alt=""
                                                                              style={{
                                                                                height: 50,
                                                                                width:
                                                                                  "auto",
                                                                              }}
                                                                              onClick={() =>
                                                                                handleSecondTreeClick(
                                                                                  item
                                                                                )
                                                                              }
                                                                            />
                                                                            <br />
                                                                            <Tooltip
                                                                              title={
                                                                                <>
                                                                                  <div>
                                                                                    <p className="text-white">
                                                                                      Team
                                                                                      :{" "}
                                                                                      {
                                                                                        item2.downlinecount
                                                                                      }
                                                                                    </p>
                                                                                  </div>
                                                                                </>
                                                                              }
                                                                            >
                                                                              <button
                                                                                type="button"
                                                                                className={`btn ${item2.status} btn-sm`}
                                                                                data-html="true"
                                                                                data-toggle="tooltip"
                                                                                data-placement="top"
                                                                                title=""
                                                                                data-original-title=""
                                                                                style={{
                                                                                  fontSize:
                                                                                    "0.9em",
                                                                                  padding:
                                                                                    "3px",
                                                                                  width:
                                                                                    "65px",
                                                                                  textAlign:
                                                                                    "center",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  item2.lvluid
                                                                                }
                                                                              </button>
                                                                            </Tooltip>
                                                                          </div>
                                                                        </li>
                                                                      </>
                                                                    );
                                                                  }
                                                                )}
                                                              </ul>
                                                            </li>
                                                          </>
                                                        );
                                                      })}
                                                    </ul>
                                                  </li>
                                                </>
                                              );
                                            }
                                          )}
                                        </ul> */}
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LevelTree;
